// Here you can add other styles
.dialog-button {
    text-align: center;
    margin-top: 25px;
    cursor: pointer;
    button {
        border-radius: 7px !important;
        font-family: "Helvetica";
        margin: 5px;
    }
}

.modal-title {
    font-weight: 700;
    text-align: center;
}

.input-text {
    margin-left: 0px;
    width: 100%;
    background: #EEEFF6;
    border-color: #EEEFF6 !important;
    font-family: "Helvetica";
    font-size: 12px;
}

.hyperlink {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: blue;
    cursor: pointer;
    text-decoration: underline;

    a {
        color: blue !important;
    }
}